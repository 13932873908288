import React, { useState, useEffect } from 'react'

import useWidthResize from 'src/hooks/useWidth'
import useScroll from 'src/hooks/useScroll'

import { Link } from './style'

const WIDTH_MD = 768

type OpenAccountLinkType = {
  articleCategory?: string;
}

const OpenAccountLink = ({ articleCategory }: OpenAccountLinkType) => {
  const [ prevScroll, setPrevScroll ] = useState(0)
  const [ transitionClass, setTransitionClass ] = useState('close-banner')
  const [ articleIsPJ, setArticleIsPJ ] = useState(false)
  const scrollPositionY = useScroll(100)
  const windowWidth = useWidthResize(1000)
  const isMobile = windowWidth <= 575

  const openAccountPFLink = 'https://intergo.app/a644613c'
  const openAccountPJLink = 'https://www.bancointer.com.br/empresas/conta-digital/pessoa-juridica/'

  useEffect(() => {
    articleCategory?.includes('para-o-empreendedor') && setArticleIsPJ(true)
  }, [ ])

  useEffect(() => {
    const smartBannerMobilePhone = scrollPositionY >= window.innerHeight - 70 && windowWidth < WIDTH_MD
    const scrollToBottom = prevScroll < scrollPositionY
    const newPrevScroll = scrollPositionY

    setPrevScroll(newPrevScroll)

    if (scrollToBottom && smartBannerMobilePhone) {
      setTransitionClass('open-banner')
    } else if (scrollPositionY <= window.innerHeight) {
      setTransitionClass('close-banner')
    }
  }, [ scrollPositionY ])

  return (
    <>
      {
        isMobile ? (
          <Link
            className={transitionClass}
            href={articleIsPJ ? openAccountPJLink : openAccountPFLink}
            target='_blank' rel='noreferrer'
          >
            Abrir conta gratuita
          </Link>
        ) : <></>
      }
    </>
  )
}

export default OpenAccountLink
